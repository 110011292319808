<template lang="pug">
v-card
  .create-new-modal

    v-card-title
      h2.card-title {{ 'modals.expense.create.title' | translate }}

    v-card-content
      v-select.mb-2(
        :label="$t('base.ingredient')"
        :options="ingredientsList"
        v-model="form.ingredient")

      v-select.mb-2(
        :label="$t('modals.expense.create.reason_title')"
        item-value="value"
        :options="options.reasons_types"
        v-model="form.reason")

      v-text-field.mb-2(
        color-gray
        :label="$t('base.weight')"
        v-model="form.weight")

      v-text-field.mb-3(
        color-gray
        :label="$t('base.reason_description_title')"
        v-model="form.reason_description")

    v-card-actions
      v-btn(
        :disabled="!isIngredientSelected || !isWeightValid || !isReason || !isReasonDescription"
        :loading="loading"
        @click="onCreate") {{ 'actions.expense' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreateNewModal',

  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    activeTab: 'Вага',
    form: {
      ingredient: null,
      weight: '',
      reason: '',
      reason_description: ''
    },
    options: {
       reasons_types: [
        { name: 'modals.expense.create.reasons.manual_feed', value: 'manual' },
        { name: 'modals.expense.create.reasons.expense', value: 'write-off' }
      ]
    },
    loading: false
  }),

  computed: {
    isIngredientSelected () {
      return this.form.ingredient !== null
    },

    isWeightValid () {
      return !!this.form.weight
    },

    isReason () {
      return !!this.form.reason
    },

    isReasonDescription () {
      return !!this.form.reason_description
    },

    ...mapGetters([
      'ingredientsList'
    ])
  },

  mounted () {
    this.fetchIngredientsList()
  },

  methods: {
    ...mapActions([
      'fetchIngredientsList',
      'addWriteOff'
    ]),

    async onCreate () {
      this.loading = true
      const payload = {
        ingredient_id: this.form.ingredient,
        weight: +this.form.weight,
        reason: this.form.reason,
        reason_description: this.form.reason_description
      }

      const { data, error } = await this.addWriteOff(payload)
      this.loading = false
      if (error) {
        if (data.error) {
          this.$toasted.show(data.error, {
            position: 'bottom-center',
            duration: 5000
          })
        } else {
          for (const key in data.errors) {
            this.$toasted.show(data.errors[key], {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      } else {
        this.fetchIngredientsList()
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .create-new-modal {
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__select {
      width: calc(100% - 5.5rem);
    }

    &__qr {
      display: inline-flex;
      outline: none;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }

    &__tabs-wrap {
      // padding: 1rem 2rem 2rem;
      background-color: $color-gray-light-3;
      border: .1rem solid $color-gray-light-2;
      border-radius: .8rem;
    }

    &__tabs-body {
      padding: 0 2rem 2rem;
    }

    &__tabs-list {
      padding: 1rem 2rem 0;
      display: flex;
      border-bottom: .1rem solid $color-gray-light-2;
    }

    &__tabs-item {
      padding-bottom: .7rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: $color-gray;
      cursor: pointer;
      user-select: none;

      &--active {
        color: $color-black;
        border-bottom: .3rem solid $color-brand;
      }
    }

    &__tabs-item:not(:last-child) {
      margin-right: 2rem;
    }
  }
</style>
