<template lang="pug">
v-card
  v-card-title
    h2.card-title {{ 'base.filter' | translate }}
  v-card-content
    .cols-x
      .col-x-2
        v-date-field(
          :label="$t('base.date_from')"
          v-model="form.date_from")
      .col-x-2
        v-date-field(
          :label="$t('modals.storage.filter.date_to')"
          v-model="form.date_to")

    v-select.mb-3(
      :label="'base.ingredient' | translate"
      :options="ingredientsList"
      v-model="form.ingredient_id")

  v-card-actions
    v-btn.mr-2(
      :loading="loading"
      @click="onApply") {{ 'actions.apply' | translate }}
    v-btn(
      text
      @click="onReset") {{ 'actions.clear' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FiltersModal',

  async mounted () {
    await this.fetchIngredientsList()

    const filters = this.$store.getters.writeOffsFilters
    for (const key in filters) {
      this.form[key] = filters[key]
    }
  },

  data: () => ({
    form: {
      date_from: null,
      date_to: null,
      ingredient_id: null
    },
    loading: false
  }),

    computed: {
    ...mapGetters([
      'ingredientsList'
    ])
  },

    methods: {
    ...mapActions([
      'fetchIngredientsList',
      'applyWriteOffsFilters'
    ]),

    async onApply () {
      this.loading = true
      const filters = {}
      for (const key in this.form) {
        if (this.form[key] !== null) {
          filters[key] = this.form[key]
        }
      }
      await this.applyWriteOffsFilters(filters)
      this.loading = false
      this.$emit('close')
    },

    onReset () {
      for (const key in this.form) {
        this.form[key] = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
