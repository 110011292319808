<template lang="pug">
div
  .sidebar-layout__toolbar
    button.btn.btn--brand.mr-2(
      @click="modals.showFilters = true")
      font-awesome-icon.mr-1(
        size="sm"
        icon="filter")
      span {{ isMobile ? 'base.filter' : 'actions.add_filter' | translate }}

    v-dialog(
      max-width="450"
      v-model="modals.showFilters")
      filters-modal(
        @close="modals.showFilters = false")

    button.btn.btn--brand(
      v-permission-hide="permissions.create_accounting"
      @click="modals.showCreateNew = true")
      font-awesome-icon.mr-1(
        size="sm"
        icon="plus")
      span {{ isMobile ? 'actions.create_mobile' : 'actions.write_off_storage' | translate }}

    v-dialog(
      max-width="450"
      v-model="modals.showCreateNew")
      create-new-modal(
        :is-mobile="isMobile"
        @close="closeModalCreate")

  navigation
  //- FOR DESKTOP AND TABLETS
  template(v-if="!isMobile")
    expense-table

  //- FOR MOBILE ONLY
  template(v-if="isMobile")
    expense-table-mobile

</template>

<script>
import { mapGetters } from 'vuex'

import adaptationMixin from '@/mixins/adaptation.mixin'

import Navigation from '../common/Navigation'

import FiltersModal from './modals/FiltersModal'
import CreateNewModal from './modals/CreateNewModal'
import permissions from '@/util/permissions'

const ExpenseTable = () => import('./ExpenseTable')
const ExpenseTableMobile = () => import('./ExpenseTableMobile')

export default {
  name: 'StorageExpense',

  mixins: [adaptationMixin],

  components: {
    Navigation,
    ExpenseTable,
    ExpenseTableMobile,
    FiltersModal,
    CreateNewModal
  },

  data: () => ({
    modals: {
      showFilters: false,
      showCreateNew: false
    },
    permissions: permissions
  }),

  computed: {
    ...mapGetters([
      'hasWriteOffsFilters'
    ])
  },

  methods: {
    closeModalCreate () {
      this.modals.showCreateNew = false
      this.$router.go(0)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
